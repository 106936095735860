import React, { useEffect, useRef, useState } from 'react';
import '../App.css';
//import Pic2 from '../images/pic_2.jpeg';
//import Pic3 from '../images/pic_3.jpeg';
//import Pic4 from '../images/pic_4.jpeg';
import Tengulogocute from '../images/tengulogocute.png';
import SlideInDiv from './slider1.js'
import SlideInDivLeft from './slider2.js'
import BGanimation from './BGanimation.js'
import Footer from './footer.js'
import useListBoxWithTextBox from './slideshow.js';
import IntroVideo from '../images/intro_video.mp4';
import BGwordanimation from './BGwordanimation.js'
import VideoComponent from './VideoComponent.js';
import { motion, useInView } from 'framer-motion';
import { useScrollBehavior } from './useScrollBehavior.js';
import { delay } from 'lodash';

  

const Home = () => {
	const [isVisibleCenter, setIsVisibleCenter] = useState(false);
    const centerRef = useRef(null);
	const ref = useRef(null);
	const isInView = useInView(ref, { once: true });

	useScrollBehavior();


	const handleScroll = () => {

		

	
		if (centerRef.current) {
		  const centerRect = centerRef.current.getBoundingClientRect();
		  const triggerOffsetCenter = 200; // Adjust this value for different trigger points
		  const triggerPointCenter = window.innerHeight - triggerOffsetCenter;
		  const isAboveTriggerPointCenter = centerRect.top < triggerPointCenter;
		  setIsVisibleCenter(isAboveTriggerPointCenter);
		}
	  };
	
	  useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		handleScroll(); // Check visibility on initial render
		return () => {
		  window.removeEventListener('scroll', handleScroll);
		};
	  }, []);
	

	
	

	const { ListBox, selectedItem } = useListBoxWithTextBox();
	
	const fadeTrigger = 300; // Adjust this value to control when the fade-out starts
	const fadeDuration = 3; // Adjust this value to control the duration of the fade-out
	
	
	useEffect(() => {
		const hash = window.location.hash.substring(1);
		if (hash) {
		  const targetElement = document.getElementById(hash);
		  if (targetElement) {
			targetElement.scrollIntoView({ behavior: 'smooth' });
		  }
		}
	  }, []);


	  /*useEffect(() => {
		const handleScroll = () => {
		  const currentPosition = window.scrollY;
		  setScrollPosition(currentPosition);
	
		  if (currentPosition >= fadeTrigger) {
			// Calculate opacity based on the fade duration
			const opacity = 1 - Math.min((currentPosition - fadeTrigger) / (fadeDuration * 100), 1);
			setVideoOpacity(opacity);
		  } else {
			// Keep the video at full opacity until the trigger point
			setVideoOpacity(1);
		  }
		};
	
		window.addEventListener('scroll', handleScroll);
	
		return () => {
		  window.removeEventListener('scroll', handleScroll);
		};
	  }, [scrollPosition, fadeTrigger, fadeDuration]);*/


	  const letterAnimation = {
		hidden: { opacity: 0 },
		visible: (i) => ({
		  opacity: 1,
		  transition: {
			delay: i * 0.05,
			duration: 0.5,
		  },
		}),
	  };
	
	  const AnimatedText = ({ text }) => {
		const characters = text.split('').map((char, i) => ({
		  char,
		  key: i
		}));
		return (
		  <div style={{ display: 'inline-block' }}>
			{characters.map(({ char, key }) => (
			  <motion.span
				key={key}
				custom={key}
				initial="hidden"
				animate="visible"
				variants={letterAnimation}
				style={{ display: 'inline-block', whiteSpace: char === ' ' ? 'pre' : 'normal' }}
			  >
				{char}
			  </motion.span>
			))}
		  </div>
		);
	  };
	// Define the bounce animation
	/*const bounceAnimation = {
		hidden: { y: -150, opacity: 0 },
		visible: {
			y: [-180, 0, -90, 0],
			opacity: [0, 1, 1, 1],
			transition: {
				duration: 1.5,
				
			},
		},
	};*/

	const bounceAnimation = {
		hidden: { opacity: 0, y: "-100%" },
		visible: {
		  opacity: 1,
		  y: [-200, 0, -30, 15, -7, 0], // Creating a realistic bounce effect
		  scaleY: [0.5,1, 1.2, 0.9, 1.1, 1], // Adding scaling effect for more realistic bounce
		  transition: {
			delay: .5, // Delay before the animation starts
			duration: 1.5,
			ease: "easeOut",
		  },
		},
	  };
	  

	const flipAnimation = {
		hidden: { rotateX: -90, opacity: 0 },
		visible: {
			rotateX: [ -90, 0 ],
			opacity: [0, 1],
			transition: {
				duration: 1,
				ease: "easeInOut",
				delay: 1,  // Adjust this value for the desired delay
			},
		},
	};

	useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisibleCenter(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 } // Adjust the threshold as needed
        );

        if (centerRef.current) {
            observer.observe(centerRef.current);
        }

        return () => {
            if (centerRef.current) {
                observer.unobserve(centerRef.current);
            }
        };
    }, []);

	const slideUpAnimation = {
		hidden: { opacity: 0, y: 50 },
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 0.8,
				delay: .8,
				ease: "easeInOut"
			}
		}
	};
	
	

	return (
		<div id='index_div1'>
			{/* Video Background */}
			<VideoComponent fadeTrigger={fadeTrigger} fadeDuration={fadeDuration} videoSrc={IntroVideo} />
        	
      		<div id='test'>
				<div id='index_div2'>
					<AnimatedText text="Yokai Software and Web Development" />
				</div>
			</div>
			<div id='textbox_1'>
				<AnimatedText text="Crafting Digital Realities, Tailored to Your Vision." />
			</div>
			<div id='spacer_div'>
				<div id='spacer_container' ref={centerRef}>
				<div  className={`center_indexdiv ${isVisibleCenter ? 'visible' : ''}`} 
								style={{ transitionDelay: '.5s' }}>
					<motion.h1
							id="explore-services-heading"
                            initial="hidden"
                            animate={isVisibleCenter ? "visible" : "hidden"}
                            variants={flipAnimation}
                        >Explore our services
                        </motion.h1>
						<br></br>
					<p id='center_indexdivpara'>We produce business-focused and effective solutions. 
					   Our expertise lies in offering top-notch web development 
					   and UI/UX services while also prioritizing broader perspectives. 
					   Beyond design and technology, we emphasize transparency, strong partnerships, 
					   and mutual trust in all our endeavors. Discover how we can enhance your impact.</p>
				</div>
				</div>
			</div>
	<div className='slid-In_div_container'>	
		<div className='slide_row1'>
			
			<SlideInDiv>
				<div id='textbox_2'>
					<div id='textbox_2_subdiv'>
						
					</div>
				</div>	
			</SlideInDiv>
			
				<div id='white-space1'>
      				<div className='white-space-text'>
					  <br></br>
						<h1>Robust Development Solutions for Seamless Performance</h1>
						<p> Web & Graphic Design
    						Website Redesigns
    						Web Development
    						Corporate Identity & Branding
    						Search Engine Optimizations</p>
					  
					 
    				</div>
    			</div>
			</div>	
				<div class="separator2"></div> 
			<div className='slide_row1'>
				<div id='white-space2'>
      				<div className='white-space-text'>
					  <br></br>
						<h1>Captivating Designs That Engage and Convert</h1>
							<br></br>
						<p> Let us handle your development needs, so you can focus on what matters most - your business. 
							From design to development 
							and ongoing support. Custom built from scratch or 
							code-takeover project.</p>
					  
      				</div>
    			</div>
			<SlideInDivLeft>
				<div id='textbox_sometext2'>
					<div id='someText1' className="outlined-text">
					<h1>Web Design</h1>	
					<br></br>
					  <p>At Yokai Software, we create stunning and user-friendly web designs 
						   that make a lasting first impression. Our services include visually 
						   engaging designs that capture attention, customized graphics and intuitive 
						   navigation, and tailoring to reflect your brand’s identity and vision, all 
						   aimed at elevating your presence in a crowded marketplace.</p>
						<div class="separator"></div> 
					  <br></br>
					  <h1>Web Development</h1>
						<p>In today’s fast-paced digital world, a high-performing website is essential. 
							We bring customers robust and tailored scalable solutions, cutting-edge technologies 
							optimizing performance, clean and efficient code for better maintainability, 
							and mobile-responsiveness for seamless experiences.</p>
						
						
					</div>
				</div>
			</SlideInDivLeft>
			</div>	
		
	</div>
	<div id='bg_container1'>
		<BGanimation circleClassName="circle1"/>
		<BGwordanimation wordClassName="word1"/>
	</div>
		
			<div className="your-component">
				<ListBox />
			</div>
		<div className='slid-In_div_container2'>
		<div className='slide_row2'>
            
		<div className='sub_slide_row1'>
			<SlideInDiv style={{ transitionDelay: '.3s' }}>
				<div className='someText2'>
				<div id='someTextsub2' className="outlined-text">
				<h1>Two Pricing Models</h1>
				</div>
				</div>
			</SlideInDiv>
			<SlideInDiv style={{ transitionDelay: '.6s' }}>
				<div className='someText2'>
				<div id='someTextsub3' className="outlined-text">
				<h1>Subscription</h1>
				   <br></br>
					<p>Tailored Solutions for Your Specific Projects with Clear Timelines and Outcomes </p>
				</div>
				</div>
			</SlideInDiv>
			<SlideInDiv style={{ transitionDelay: '.9s' }}>
				<div className='someText2'>
				<div id='someTextsub4' className="outlined-text">
				<h1>Project-Based</h1>
				   <br></br>
					<p>Yokai Software's project-based pricing delivers tailored 
						solutions with precision and expertise for your unique needs </p>
				</div>
				</div>
			</SlideInDiv>
		</div>
			<SlideInDivLeft>
				<div id='textbox_3'>
			
				</div>
            </SlideInDivLeft>
		</div>
		</div>
			<div id='bg_container2'>
				<BGanimation circleClassName="circle2"/>
				<BGwordanimation wordClassName="word2"/>
			</div>
		<div id='index_info_div'>
			<div id='index_info_div_sub1'>
				<div id='index_info_div_sub1_heding'>Empowering Your Digital Journey</div>
				<div>Collaborate with our expert team to achieve your online goals with innovation and precision.</div>
			</div>
			<div id='index_info_div_sub2'>
				<div id='index_info_1'>
				<div className='index_info_heading'>Responsive Design</div>
				<div className='index_info_subheading'>Optimized for All Devices</div>
				<div>In today’s mobile-driven landscape, your site needs to shine on every device. 
					Our team will make sure your business captivates customers and delivers a seamless 
					experience, even before they step through your doors.</div>
				</div>	
				<br></br>	
				<div id='index_info_2'>
				<div className='index_info_heading'>Digital Strategy</div>
				<div className='index_info_subheading'>Strategic Insights and Innovative Solutions</div>
				<div>Crafted with precision, our digital strategies offer integrated approaches to 
					enhance your online presence, navigate the digital landscape, and drive measurable 
					results. Let us guide you with cutting-edge tactics to achieve your business goals 
					and stand out in a competitive market.</div>
				</div>			
			</div>
			<div id='index_info_div_sub3'>
				<div id='index_info_3'>
				<div className='index_info_heading'>E-commerce Websites</div>
				<div className='index_info_subheading'>Seamless Online Sales</div>
				<div>Ready to boost your sales online? E-commerce solutions are essential 
					for thriving in the digital marketplace, allowing you to reach customers 
					locally, nationally, or globally.</div>
				</div>	
				<br></br>
				<div id='index_info_4'>
				<div className='index_info_heading'>Brand identity</div>	
				<div className='index_info_subheading'>Crafting Unique and Memorable Impressions</div>
				<div>Build a distinctive brand that stands out and resonates. Our approach to 
					brand identity focuses on creating compelling, memorable visuals and messages 
					that capture the essence of your business, engage your audience, and foster a 
					strong connection with your target market. Let us help you define and communicate 
					your brand's unique story.</div>
				</div>	
			</div>


		</div>
			
		<div id='index_div_container'>
			<div id='bottom_index_heading1' ref={ref}>
                <motion.h1
                    initial="hidden"
                    animate={isInView ? "visible" : "hidden"}
                    variants={bounceAnimation}
				>Start today
                </motion.h1>
            </div>
			
			<a href="/security" style={{ textDecoration: 'none' }}>
			<motion.div
                    id='index_div3'
                    initial="hidden"
                    whileInView="visible"
                    variants={slideUpAnimation}
                    viewport={{ once: true }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                    <div id='indextextdiv1'>Security</div>
                    <img id='Tengulogocute_2' src={Tengulogocute} alt="Tengulogocute2" />
                    <div id='indextextdiv1_sub'>Let's talk about our security partners</div>
                </motion.div>
			</a>
			<a href="/appdev" style={{ textDecoration: 'none' }}>
			<motion.div
                    id='index_div4'
                    initial="hidden"
                    whileInView="visible"
                    variants={slideUpAnimation}
                    viewport={{ once: true }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                    <div id='indextextdiv2'>APP DEV</div>
                    <img id='Tengulogocute_3' src={Tengulogocute} alt="Tengulogocute3" />
                    <div id='indextextdiv2_sub'>Get Started With Your Customized App</div>
                </motion.div>
			</a>
			<a href="/contact" style={{ textDecoration: 'none' }}>
			<motion.div
                    id='index_div5'
                    initial="hidden"
                    whileInView="visible"
                    variants={slideUpAnimation}
                    viewport={{ once: true }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                    <div id='indextextdiv3'>Contact Us</div>
                    <img id='Tengulogocute_4' src={Tengulogocute} alt="Tengulogocute4" />
                    <div id='indextextdiv3_sub'>Contact Us And Get Started</div>
                </motion.div>
			</a>
			<div id='bottom_index_heading2'>
					
			</div>
			</div>
			
			<Footer/>
		</div>

	);
};

export default Home;


/*useEffect(() => {
			// Scroll to the top when the component mounts
			window.scrollTo(0, 0);
		  }, []); // Empty dependency array ensures this effect runs only once*/


//<img id='pic_3' src={Pic3} alt="Pic3"/>
//<img id='pic_4' src={Pic4} alt="Pic4"/>

/*we focus on every detail 
						 to create a website that not only looks great but also delivers an 
						 exceptional user experience*/

		
/*With our comprehensive development process, including thorough testing and ongoing 
						 support, you can be confident that your website will operate smoothly and efficiently, 
						 giving you more time to focus on what you do best—running your business*/	

/*<h2>At Yokai Software, we create stunning and user-friendly web designs that make a lasting first impression.</h2>
<div class="flex-item"> Visually engaging designs that capture attention</div>
    					<div class="flex-item"> Customized graphics and intuitive navigation</div>
    					<div class="flex-item"> Tailored to reflect your brand’s identity and vision</div>
    					<div class="flex-item">Elevateing customers in a crowded marketplace..</div>
						
						<h2>In today’s fast-paced digital world, a high-performing website is essential. we bring customers ..</h2>
					  <br></br>
					  	<div id="bullet_container2">
    						<div class="flex-item2">Robust and tailored scalable solutions.</div>
    						<div class="flex-item2">Cutting-edge technologies optimizing performance.</div>
    						<div class="flex-item2">Clean and efficient code for better maintainability.</div>
    						<div class="flex-item2">Mobile-responsiveness for seamless experiences.</div>
    						
					  	</div>*/	
						
/*Our Project-Based Model provides a flexible solution 
for clients seeking focused and high-impact results. 
This model allows you to engage with our specialized team for specific 
projects or tasks, such as design, development, or maintenance, on a 
per-project basis. You’ll benefit from tailored strategies and deliverables 
with clear timelines and defined outcomes, ensuring that your project’s 
unique requirements are met without the commitment of a long-term contract.*/


/*At Yokai Software, our project-based approach delivers specialized services 
designed to meet your immediate needs with precision and expertise. Whether you 
require targeted solutions in design, development, or maintenance, our team commits 
to understanding and fulfilling your project’s unique objectives. We blend creativity 
with technical excellence to craft custom solutions that align perfectly with your 
goals, ensuring each project is executed with the highest level of craftsmanship and dedication.*/
