import React, { useEffect } from 'react';
import Pic15 from '../images/pic_15.jpeg';
import ContactForm from './ContactForm.js'
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import Footer from './footer.js'
import { useScrollBehavior } from './useScrollBehavior.js';

const Contact = () => {

	useScrollBehavior();

	useEffect(() => {
		// Scroll to the top when the component mounts
		window.scrollTo(0, 0);
	  }, []); // Empty dependency array ensures this effect runs only once
	

	return (
		<div id='contact_div1'>
			<img id='pic_15' src={Pic15} alt="Pic" />
			<div id='contact_head'><h1>Let's Connect</h1></div>
			<div id='contact_div3'>	
				<div id='contact_textdiv1'>
					<br></br>
          			<p>Whether you are curious about our services, have a project on your mind or 
						would like to partner with us, we are happy to discuss your needs.
						We are looking forward to hearing about you.
          			</p>
					  
        		</div> 
				
			</div>
			
			<div id='contact_div4'>
			  
        	<div id='contactform_div'>
          		<ContactForm/>
        	</div>
      		</div>
			  <div id='contact_subheading' className="outlined-text"></div>
			  <div id='contact_div5'>
			  	<div id='contact_heading'>
						<h1>Or Reach Us At</h1>
				</div>
				<div id='contact_div2'>
					<p>yokaisoftware66@gmail.com</p>
					<p id='cont_number'>941-613-4645</p>
				</div>
				<div>
				<div id="social-icons2">
                  <a id='fafacebook' href="https://www.facebook.com/profile.php?id=61559579340148&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                    <FaFacebook size={50} className="facebook" />
                  </a>
                  <a href="https://www.instagram.com/yokaisoftware?igsh=MTBjbWhiM294NXNxbA%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
                    <FaInstagram size={50} className="instagram" />
                  </a>
                  <a href="https://www.linkedin.com/company/yokai-software-and-web-development/about/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin size={50} className="linkedin" />
                  </a>
                </div>
				</div>
			</div> 
			  
			
			<Footer/>
		</div>
	);
};

export default Contact;


//className="outlined-text"
/*<div id='contact_heading'>
						<h1>Contact Us</h1>
				</div>*/