import React, { useState } from 'react';
import {  useEffect } from 'react';
import '../App.css';


const useListBoxWithTextBox = () => {
  const [selectedItem, setSelectedItem] = useState(1);
  //const [visible, setVisible] = useState(false);
  const [fadeType, setFadeType] = useState(null);
  //const [listBoxActive, setListBoxActive] = useState(false);
  

  const handleItemClick = (item) => {
    setFadeType('fade-out');
    setTimeout(() => {
      setSelectedItem(item === selectedItem ? null : item);
      setFadeType('fade-in');
    }, 1000); // Adjust the delay time (in milliseconds) as needed
  };

  useEffect(() => {
    const textBoxElement = document.querySelector('.text-box');

    const handleAnimationEnd = () => {
      if (fadeType === 'fade-out') {
        setSelectedItem(null);
      }
    };

    if (textBoxElement) {
      textBoxElement.addEventListener('animationend', handleAnimationEnd);
    }

    return () => {
      if (textBoxElement) {
        textBoxElement.removeEventListener('animationend', handleAnimationEnd);
      }
    };
  }, [fadeType]);

  const ListBox = () => (
    <div className="list-box">
      <div id='listbox_heading'>
      <ul>
        <li onClick={() => handleItemClick(1)}><h2 className='list_element' id='WD_element'>Website Development</h2></li>
        <li onClick={() => handleItemClick(2)}><h2 className='list_element' id='AD_element'>APP Development</h2></li>
        <li onClick={() => handleItemClick(3)}><h2 className='list_element' id='API_element'>API Integration</h2></li>
      </ul>
      </div>
      {selectedItem !== null && (
        <div className={`text-box ${fadeType}`} key={selectedItem}>
          {getTextForItem(selectedItem)}
        </div>
      )}
    </div>
  );

  const getTextForItem = (item) => {
    switch (item) {
      case 1:
        return (
          <div id='slide_div1' className='slide_divs_fonts'>
            <h1>Highly Specialized Websites</h1>
            <p>We build customized websites that enhance business and 
              satisfies the unique needs of our customers.</p>
            <p>From responsive web design and content management to eCommerce,
              we develop custom sites that focus on performance, stability, and flexibility.</p>
            {/* Add additional HTML elements as needed */}
          </div>
        );
      case 2:
        return (
          <div id='slide_div2' className='slide_divs_fonts'>
            <h1>APP Development</h1>
            <p>Delivering web & mobile apps across multiple platforms
              along with cross-platform and hybrid applications 
              to help businesses target multiple platforms using
              the same code base for Windows, iOS and Android platforms.
              as well as delivering multi-purpose cross-platform mobile application 
              design</p>
            {/* Add additional HTML elements as needed */}
          </div>
        );
      case 3:
        return (
          <div id='slide_div3' className='slide_divs_fonts'>
            <h1>API Integration</h1>
            <p>Custom API Integration Solutions</p>
            <p id="app_para2"> APIs are powerful tools for integrating
              different systems and enabling data exchange. 
              We provide custom third-party web API integration services
              to connect with websites and applications..</p>
            {/* Add additional HTML elements as needed */}
          </div>
        );
      default:
        return null;
    }
  };

 

  return { ListBox, selectedItem };
};

export default useListBoxWithTextBox;