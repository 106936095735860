import React, { useRef, useEffect } from 'react';
import '../App.css';
import Pic2 from '../images/pic_2.jpeg';
import Pic7 from '../images/pic_7.jpeg';
import Pic8 from '../images/pic_8.jpeg';
import Pic9 from '../images/pic_9.jpeg';
import Pic10 from '../images/pic_10.jpeg';
import Pic11 from '../images/pic_11.JPG';
import Tengulogocute from '../images/tengulogocute.png';
import { Carousel} from 'react-bootstrap';
import BGanimation from './BGanimation.js';
import BGwordanimation from './BGwordanimation.js';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Footer from './footer.js'
import { useScrollBehavior } from './useScrollBehavior.js';

const Webdev = () => {

  useScrollBehavior();

  
	

	const images = [Pic9, Pic2, Pic10, Pic11];
	
	  const texts = [
		'<span class="letter-f">Full</span> Stack Develpoment',
		'<span class="letter-w">W</span><span class="letter-e">e</span><span class="letter-b">b</span> <span class="letter-d">D</span><span class="letter-e2">e</span><span class="letter-v">v</span><span class="letter-e3">e</span><span class="letter-l">l</span><span class="letter-o">o</span><span class="letter-p">p</span><span class="letter-m">m</span><span class="letter-e4">e</span><span class="letter-n">n</span><span class="letter-t">t</span>',
		'Mobile-Responsive',
		'<span class="letter-u">U</span><span class="letter-x">X</span> <span class="letter-d">D</span><span class="letter-e">e</span><span class="letter-sign">sign</span>',
	  ];

    const textStyles = [
      'text-left1', // Default style for the first text
      'text-left2', // Style for the second text
      'text-left3', // Default style for the third text
      'text-left4', // Default style for the fourth text
    ];

    const texts2 = [
      'We craft remarkable digital presences while offering full-stack solutions for all your software needs. We primarily use React but we are not limited by one language and can adapt to meet the projects needs',
      'From responsive web design and content management to eCommerce, we develop custom sites that focus on performance, stability, and flexibility.',
      'Mobile-responsive web design services incorporated into every element of your website. Simple layouts and digital content that can cater to our customers needs',
      'UX, short for User Experience, is the driving core of our websites making use of marketing-oriented UX design to anticipate your users needs and build experiences tailored to them.',
    ];


	  const imageCarouselRef = useRef(null);
    const textCarouselRef1 = useRef(null);
    const textCarouselRef2 = useRef(null);
  //const [animating, setAnimating] = useState(false);

  const handlePrev = () => {
    imageCarouselRef.current.prev();
    textCarouselRef1.current.prev();
    textCarouselRef2.current.prev();
  };

  const handleNext = () => {
    imageCarouselRef.current.next();
    textCarouselRef1.current.next();
    textCarouselRef2.current.next();
  };

  


	return (
		<div id='wd_div1'>
			<div id='wd_picdiv1'>
        <img id='pic_8' src={Pic8} alt="Pic" />
      </div>
				
      <div id='wb_heading_container'>
				<div id='wd_heading_div'>
					<h1 id='wd_heading'>Web development</h1>
				</div>
				<div id='wd_subheading_div'>
					<p>We develop and design custom websites that provide our cutomers with innovative and user friendly web experiences.  </p>
				</div>
      </div>
      <div id='wd_div2'></div>
				<div id='wd_div3'>
					<div id="wd_div4">
                		<h1 id="wd_sidediv1">Building Compelling Websites</h1>
                 		<p>Creating professional and creative websites that are mobile-responsive, search engine optimized and easy to navigate to help your site stand out.</p>
				  </div>
					<div id="wd_div5">
						<img id='pic_7' src={Pic7} alt="Pic" />
					</div>
          <BGanimation circleClassName="circle3">
          <BGwordanimation wordClassName="word3">
          </BGwordanimation>
          </BGanimation>  
        </div>
        
      
        
        <div id='wd_div5_5'>
        
        <div className='slider-container2'>
					<div className="text-container">
            		<Carousel id="textCarousel" ref={textCarouselRef1} interval={null} indicators={false}  keyboard={false} controls={false}>
              {texts.map((text, idx) => (
                <Carousel.Item key={idx} id='carousel_Item'  className={textStyles[idx]}>
                  <div className="text-slide" dangerouslySetInnerHTML={{ __html: text }}>
                    
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <div className='text-container2'>
            <Carousel
              id='textCarousel2'
              ref={textCarouselRef2}
              interval={null}
              indicators={false}
              keyboard={false}
              controls={false}
            >
              {texts2.map((text, idx) => (
                <Carousel.Item key={idx} id='carousel_Item2'>
                  <div className='text-slide2'>
                    <p>{text}</p>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <div id='imageCarousel_div'>
          <Carousel id="imageCarousel" ref={imageCarouselRef} controls={false} data-bs-ride="carousel" interval={null} indicators={false}>
            {images.map((image, idx) => (
              <Carousel.Item key={idx} id='carousel_item2'>
                <img
                  id='carousel_images'
                  className="d-block w-100"
                  src={image}
                  alt={`Slide ${idx}`}
                  
                />
              </Carousel.Item>
            ))}
          </Carousel>
          </div>
          
          </div>
          
          </div> 
          <div id='button_case'>
		        <div id='button_divs'>
              <button id='nxt_button' onClick={handleNext}>
                NXT
              </button>
              <button id='prv_button' onClick={handlePrev}>
                PRV
              </button>
            </div>
          </div>
          <div id='wd_insert'>
              <div id='wd_sub_insert'>
                <h1>Website Maintenance </h1>
                <br></br>
                <p>Our website maintenance services ensure your company’s site 
                  stays healthy and up-to-date with 24/7 code scanning, daily 
                  backups, uptime monitoring, CMS updates, plugin updates, 
                  website speed optimization, and much more. </p>
              </div>
          </div>
      <div id='wd_div6'>
      <BGanimation circleClassName="circle4">
      <BGwordanimation wordClassName="word3_5">
            <div id='bottom_wd_heading1'>
					      <h1>Start Today</h1>
			      </div>
            <a href="/portfolio" style={{ textDecoration: 'none' }}>
			        <div id='wd_div7'>
				          <div id='wd_textdiv1'>Our Portfolio</div>
                  <img id='Tengulogocute_5' src={Tengulogocute} alt="Tengulogocute5"/>
                  <div id='wdtextdiv1_sub'>Take a look at our work</div>
			        </div>
            </a>
            <a href="/contact" style={{ textDecoration: 'none' }}>
			        <div id='wd_div8'>
				          <div id='wd_textdiv2'>Contact US</div>
                  <img id='Tengulogocute_6' src={Tengulogocute} alt="Tengulogocute6"/>
                  <div id='wdtextdiv2_sub'>Contact Us And Get Strated</div>
			        </div>
            </a>
            <a href="/security" style={{ textDecoration: 'none' }}>
			        <div id='wd_div9'>
				          <div id='wd_textdiv3'> Security</div>
                  <img id='Tengulogocute_7' src={Tengulogocute} alt="Tengulogocute7"/>
                  <div id='wdtextdiv3_sub'>Let's talk about our security partners</div>
			        </div>
            </a>
			      <div id='bottom_wd_heading2'>
					      
			      </div>
            </BGwordanimation>
            </BGanimation>  
      </div>
      
      
			<Footer/>
		</div>
	);
};

export default Webdev;


/*useEffect(() => {
		// Scroll to the top when the component mounts
		window.scrollTo(0, 0);
	  }, []); // Empty dependency array ensures this effect runs only once*/
