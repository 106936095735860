import React, { useEffect } from 'react';
import '../App.css';
import Pic10 from '../images/pic_10.jpeg';
import Pic13 from '../images/pic_13.jpeg';
import Pic14 from '../images/pic_14.jpeg';
import Tengulogocute from '../images/tengulogocute.png';
import BGanimation from './BGanimation.js';
import BGanimation3 from './BGanimation3.js';
import BGwordanimation from './BGwordanimation.js';
import Footer from './footer.js'
import { useScrollBehavior } from './useScrollBehavior.js';

const Appdev = () => {

    useScrollBehavior();

    
	
	return (
		<div id='ad_div1'>
				<img id='pic_14' src={Pic14} alt="Pic" />
			<div id='ad_div2' className="outlined-text">
				<h1>Application and API Development Services</h1>
			</div>
			<div id='ad_div3'>
			<div id="app_pic_div">
                <div id="app_pic_div-inner">
                    <div id="app_pic_div-front">
						<img id='pic_13' src={Pic13} alt="Pic" />
                    </div>
                    <div id="app_pic_div-back">
                        <h1>Cross-Platform Mobile App Development</h1>
                        <br></br>
                        <p>develops Cross-Platform and Hybrid Applications 
                            to help businesses target multiple platforms using
                             the same code base for Windows, iOS and Android platforms.
                              We deliver multi-purpose cross-platform mobile application 
                              design.</p>
                        
                    </div>
    
                </div>

            </div>
			<div id="ad_div4">
            <h2 id="app_heading">App development</h2>
            <p id="app_para">app development services that prioritize speed and efficiency. 
                software for use across mobile operating systems, including Apple, 
                Android, and Windows devices. Offering interoperable, scalable,
                 and flexible web & mobile applications for all kinds of industries.</p>
            </div>
            <BGanimation circleClassName="circle5"/>

            <BGwordanimation wordClassName="word5"/> 

            <div id="app_pic_div2">
                <div id="app_pic_div-inner2">
                    <div id="app_pic_div-front2">
						<img id='pic_10' src={Pic10} alt="Pic" />
                    </div>
                    <div id="app_pic_div-back2">
                        <h1>Web API development</h1>
                        <br></br>
                        <p>Custom web API solutions and development for 
                            desktop, and cloud applications, 
                            firmware/middleware, databases and browsers.</p>
                        
                    </div>
    
                </div>

            </div>
            
            <div id="ad_div5">
            <h2 id="app_heading2">Custom API Intergration Solutions</h2>
            <p id="app_para2">APIs are powerful tools for integrating
                 different systems and enabling data exchange. 
                 We provide custom third-party web API integration services
                 to connect with website and applications..</p>
            </div>

			</div>
            <BGanimation circleClassName="circle6"/>

            <BGwordanimation wordClassName="word6"/>

            <div id='ad_div6'>
            <div id='bottom_ad_heading1'>
					<h1>Start Today</h1>
			</div>
            <a href="/webdev" style={{ textDecoration: 'none' }}>
			    <div id='ad_div7'>
				    <div id='ad_textdiv1'>Web Dev </div>
                    <img id='Tengulogocute_8' src={Tengulogocute} alt="Tengulogocute8"/>
                    <div id='adtextdiv1_sub'>Get Started With Your Customized Web Site</div>
			    </div>
            </a>
            <a href="/security" style={{ textDecoration: 'none' }}>
			    <div id='ad_div8'>
				    <div id='ad_textdiv2'>Security</div>
                    <img id='Tengulogocute_9' src={Tengulogocute} alt="Tengulogocute9"/>
                    <div id='adtextdiv2_sub'>Let's talk about our security partners</div>
			    </div>
            </a>
            <a href="/contact" style={{ textDecoration: 'none' }}>
			    <div id='ad_div9'>
				    <div id='ad_textdiv3'>Contact US</div>
                    <img id='Tengulogocute_10' src={Tengulogocute} alt="Tengulogocute10"/>
                    <div id='adtextdiv3_sub'>Contact Us And Get Strated</div>
			    </div>
            </a>
			<div id='bottom_ad_heading2'>
					
			</div>
            <BGanimation3 circleClassName="circle7"/>
            </div>
			
			<Footer/>
		</div>
	);
};

export default Appdev;


/*useEffect(() => {
		// Scroll to the top when the component mounts
		window.scrollTo(0, 0);
	  }, []); // Empty dependency array ensures this effect runs only once*/