import React, { useEffect }  from 'react';
import '../App.css';
import PC5 from '../images/PC5.png';
import Tengulogocute from '../images/tengulogocute.png';
import BGanimation from './BGanimation.js';
import BGanimation3 from './BGanimation3.js';
import BGwordanimation from './BGwordanimation.js';
import Footer from './footer.js'
import { useScrollBehavior } from './useScrollBehavior.js';

const Security = () => {

	useScrollBehavior();

	
	return (
		<div id='security_div1'>
			<div id='security_div2'>
				<h1>Our Sercurity Partners</h1>
				<br></br>
				<p>We partner with proxima Cenntauri to provide you with all your web security needs.
					This is space dedicated to talking about all the attributes of our security partner
					and will be completed when the companies site is completed thank you for your patience.</p>
			</div>
			<div id='security_div3'>
				<img id='PC5' src={PC5} alt="Pic" />
			</div>
			<BGwordanimation wordClassName="word7">
			<BGanimation circleClassName="circle9"/>
			<div id='security_div4'>
				<div id='sec_subhead'>
					<h1>Security Heading</h1>
					<p>This is about security and said business</p>
				</div>
			</div>
			</BGwordanimation>
			<div id='sec_div5'>
            <div id='bottom_sec_heading1'>
					<h1>Start Today</h1>
			</div>
			<a href="/contact" style={{ textDecoration: 'none' }}>
				<div id='sec_div6'>
					<div id='sec_textdiv1'>Contact US</div>
					<img id='Tengulogocute_11' src={Tengulogocute} alt="Tengulogocute11"/>
					<div id='sectextdiv1_sub'>Contact Us And Get Strated</div>
				</div>
			</a>
			<a href="/appdev" style={{ textDecoration: 'none' }}>
				<div id='sec_div7'>
					<div id='sec_textdiv2'>APP DEV</div>
					<img id='Tengulogocute_12' src={Tengulogocute} alt="Tengulogocute12"/>
					<div id='sectextdiv2_sub'>Get Started With Your Customized App</div>
				</div>
			</a>
			<a href="/portfolio" style={{ textDecoration: 'none' }}>
				<div id='sec_div8'>
					<div id='sec_textdiv3'>Our Portfolio</div>
					<img id='Tengulogocute_13' src={Tengulogocute} alt="Tengulogocute13"/>
					<div id='sectextdiv3_sub'>Take a look at our work</div>
				</div>
			</a>
			<div id='bottom_sec_heading2'>
					
			</div>
            <BGanimation3 circleClassName="circle8"/>
            </div>
			
			<Footer/>
		</div>
		
	);
};

export default Security;


/*useEffect(() => {
		// Scroll to the top when the component mounts
		window.scrollTo(0, 0);
	  }, []); // Empty dependency array ensures this effect runs only once
*/