import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './components/navbar.js';
import { BrowserRouter as Router, Routes, Route }
	from 'react-router-dom';
import Home from './pages';
import Portfolio from './pages/portfolio';
import Security from './pages/security';
import Webdev from './pages/webdev';
import Contact from './pages/contact';
import Appdev from './pages/appdev';
import smoothscroll from 'smoothscroll-polyfill';

function App() {
  smoothscroll.polyfill();

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    if (scrollTop > 300) { // Adjust this value as per your requirement
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

	return (
		<Router>
			<Navbar />
			<Routes>
				<Route exact path='/' element={<Home />} />
				<Route path='/portfolio' element={<Portfolio />} />
        <Route path='/security' element={<Security />} />
				<Route path='/contact' element={<Contact />} />
				<Route path='/webdev' element={<Webdev />} />
        <Route path='/appdev' element={<Appdev />} />
			</Routes>

      {isVisible && (
        <button className="to-top-btn" onClick={scrollToTop}>
          Top 
        </button>
      )}

		</Router>
	);
}

export default App;



//import logo from './logo.svg';
//import './App.css';

//function App() {
  //return (
    //<div className="App">
      //<header className="App-header">
        //<img src={logo} className="App-logo" alt="logo" />
        //<p>
          //Edit <code>src/App.js</code> and save to reload.
       // </p>
        //<a
         // className="App-link"
          //href="https://reactjs.org"
          //target="_blank"
          //rel="noopener noreferrer"
        //>
          //Learn React
        //</a>
      //</header>
    //</div>
  //);
//}

//export default App;
