import React from "react";
import '../App.css';
import { Nav, NavLink, NavMenu }from "./NavbarElements";
import { useState, useEffect } from 'react';
import Pic from '../images/tengulogo2.png';
import { useLocation } from 'react-router-dom';



const Navbar = () => {

	const [colorChange, setColorchange] = useState(false);
	const [showDropdown, setShowDropdown] = useState(false);
	const location = useLocation();
	const [showMenu, setShowMenu] = useState(false);
	

	function getCurrentPage() {
		const pathArray = location.pathname.split('/').filter(Boolean);
		const currentPage = pathArray.length > 0 ? pathArray[pathArray.length - 1] : 'default';
		
		return currentPage;
	  }
	
	  const getLinkStyle = (page) => {
		const isScrolled = colorChange;
		const currentPage = getCurrentPage();
		const viewportWidth = window.innerWidth;

		// Check if the link is active
		const isActive = page === currentPage || (page === 'home' && currentPage === 'default');


		
  		if (isActive) {
    			return null; // No custom styles for active link
  		}

		  

		  if (viewportWidth <= 1080) {
			return {
			  color: 'white', // Set color to white for smaller viewports
			};
		  }

		switch (currentPage) {
			case 'home':
			return {
			  color: isScrolled ? 'white' : 'black',
			 
			
			};
		  case 'portfolio':
			return {
			  color: isScrolled ? 'black' : 'black',
			  
			};
		case 'contact':
			return {
			  color: isScrolled ? 'black' : 'black',
			};
		case 'security':
			return {
			  color: isScrolled ? 'black' : 'black',
			};
			
		case 'webdev':
			return {
				color: isScrolled ? 'black' : 'black',
			};
		case 'appdev':
			return {
				color: isScrolled ? 'black' : 'white',
			};			

		  // Add more cases for other pages as needed
		  default:
			return {
			  color: isScrolled ? 'black' : 'white',
			  
			};
			
		}
	  };

	useEffect(() => {
		setColorchange(window.scrollY >= 10);
	  }, []);
	// eslint-disable-next-line
	  const changeNavbarColor = () => {
		

	setColorchange(window.scrollY >= 10);
};
	  
	
	  useEffect(() => {
		window.addEventListener('scroll', changeNavbarColor);
	
		// Cleanup event listener on component unmount
		return () => {
		  window.removeEventListener('scroll', changeNavbarColor);
		};
	  }, [changeNavbarColor]);

	  

	  const handleDropdownToggle = () => {
		setShowDropdown(!showDropdown);
	  };
	  

	  // Close the dropdown menu when the user navigates to a new page
	  useEffect(() => {
    	setShowMenu(false);
  	  }, [location]);

	  const toggleMenu = () => {
		setShowMenu(!showMenu);

	  };

	  const getBarColor = () => {
		const isScrolled = colorChange;
		const currentPage = getCurrentPage();
		
	
	
		
		switch (currentPage) {
		  case 'home':
			return isScrolled ? 'white' : 'black';
		  case 'portfolio':
			return isScrolled ? 'black' : 'black';
		  case 'contact':
			return isScrolled ? 'black' : 'black';
		  case 'security':
			return isScrolled ? 'black' : 'black';
			case 'webdev':
				return isScrolled ? 'black' : 'black';
			case 'appdev':
				return isScrolled ? 'black' : 'white';			
		  default:
			return isScrolled ? 'black' : 'white';
		}
	  };
	
	  const barStyle = {
		backgroundColor: getBarColor(),
	  };

	
	  /*useEffect(() => {
		const bars = document.querySelectorAll('.bar');
	
		if (bars) {
		  bars.forEach(bar => {
			// Add or remove colorChange class based on colorChange state
			if (colorChange) {
			  bar.classList.add('colorChange');
			} else {
			  bar.classList.remove('colorChange');
			}
		  });
		}
	  }, [colorChange]);*/
	
	

	return (
		<>
			<Nav className={`${colorChange ? 'navbar colorChange' : 'navbar'} ${showMenu ? 'active' : ''}`}>
				<NavMenu className={showMenu ? 'active' : ''}>
				<div className="brush-stroke"></div>
					<NavLink to="/" className="logo">
						
						<img id='logo' src={Pic} alt=''/>
					</NavLink>
                    <NavLink to="/" activeClassName="active" className="home" style={{ ...getLinkStyle('home')}}>
						Home
					</NavLink>
					<NavLink to="/portfolio"  activeClassName="active" style={{ ...getLinkStyle('portfolio')}}>
					    Portfolio
					</NavLink>
					<NavLink  activeClassName="active" style={{ ...getLinkStyle('')}}
					className="services"
            			onMouseEnter={handleDropdownToggle}
            			onMouseLeave={handleDropdownToggle}
          				>Services
            				{showDropdown && (
              				<div className="dropdown-content">
                			<NavLink to="/webdev" activeClassName="active" id='webdev_link' style={{ ...getLinkStyle('webdev')}}>
                  					Web Development
                			</NavLink>
                			<NavLink to="/appdev" activeClassName="active" style={{ ...getLinkStyle('appdev')}}>
                  					App Development
                			</NavLink>
              				</div>
            				)}
          			</NavLink>
					<NavLink to="/security" activeClassName="active" style={getLinkStyle('security')}>
						Security
					</NavLink>
					<NavLink to="/contact" activeClassName="active" style={getLinkStyle('contact')}>
						Contact
					</NavLink>
					
					
				</NavMenu>
					
			</Nav>
			<div className="hamburger"  onClick={toggleMenu} >
    					<span  style={barStyle} className="bar"></span>
    					<span  style={barStyle} className="bar"></span>
    					<span  style={barStyle} className="bar"></span>
					</div>
		</>
	);
};

export default Navbar;


/*<NavLink to="/sign-up" activeClassName="active" style={getLinkStyle('sign-up')}>
						Sign Up
					</NavLink>
                    <NavLink to="/secure" activeClassName="active" style={getLinkStyle('secure')}>
						Secure
					</NavLink> */