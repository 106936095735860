import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import '../App.css';
import Footer from './footer.js';
import Newsapp from '../images/newsapp.png';
import Auctions from '../images/Auctions.png';
import Social_Network from '../images/Social_Network.png';
import Search from '../images/Search.png';
import Mail from '../images/Mail.png';
import PC4 from '../images/PC4.png'
import Encyclopedia from '../images/Encyclopedia.png'
import Black_Lotus from '../images/IMG_0016.JPG';
import { useScrollBehavior } from './useScrollBehavior.js';




const TwistingDiv = ({ children, position, triggerPoint, forwardedRef }) => {
	const [ref, inView] = useInView({
	  triggerOnce: true,
	  rootMargin: `-${triggerPoint}px 0px`, // Adjust the root margin based on your requirement
	});
  
	//const twistingDivRef = useRef();
  
	useEffect(() => {
		if (inView) {
		  // Trigger animation or any other logic here
		  if (forwardedRef.current) {
			forwardedRef.current.style.visibility = 'visible';
			forwardedRef.current.classList.add('animate-pop001');
		  }
		}
	  }, [inView, forwardedRef]);
  
	return (
		<div ref={(el) => { ref(el); forwardedRef.current = el; }} className={`twisting-div position-${position}`}>
		{children}
	  </div>
	);
  };

const Portfolio = () => {

	useScrollBehavior();

	

	const ref1 = useRef();
  	const ref2 = useRef();
  	const ref3 = useRef();
  	const ref4 = useRef();
	const ref5 = useRef();
  	const ref6 = useRef();

	return (
		<div id='portfolio_div1'>
			<div id='port_head_div'>
				<div id='port_logo_div'>
                	<h1 id='port_logo_heading_p'>Our work</h1>
            	</div>
            	<div id='port_logo_para_div'>
                	<p>We partner with our clients to create exceptional digital experiences.</p>
            	</div>
				<div class="arrow-down"></div>

			</div>
			<div id='all_twistdivs'>
			<TwistingDiv  position="left" triggerPoint={50} forwardedRef={ref1}>
        		
        		<img id='port_image7' src={PC4} alt="Pic" />
				<div id='overlay7' className="overlay_1">
            	<div className="text">
				<h1>Cyber Security Site</h1>
                        <br></br>
						
                        <p>comercial cyber </p>
						<p>security services</p>
                        <p></p> 
                        <span></span>
                        <span></span>
                        <span></span>
                        <a id="sourcecode" className="port_nav-link" href="https://proxoffensive.com/">Link</a>
				</div>	
				</div>
				<h1>Cyber Security Website</h1>
                        <br></br>
                        <p>comercial cyber security services</p>
                        <p>with blog capabilities</p>
                         <p></p> 
                        <span></span>
                        <span></span>
                        <span></span>
                        <a id="sourcecode" className="port_nav-link" href="/">Link to site</a>
        		
      		</TwistingDiv>

			<TwistingDiv position="right" triggerPoint={50} forwardedRef={ref6}>
        		
        		<img id='port_image6' src={Black_Lotus} alt="Black_Lotus" />
				<div id='overlay6' className="overlay_1">
            	<div className="text">
						<h1>Local Artist Site</h1>
                        <br></br>
                        <p className='lineconst'>Under Construction</p>
                        <p>Artist site</p> 
						<p></p> 
                        <span></span>
                        <span></span>
                        <span></span>
                        <a id="sourcecode" className="port_nav-link" href="/">Source code</a>
				</div>	
				</div>
        		
      		</TwistingDiv>
			<TwistingDiv  position="left" triggerPoint={50} forwardedRef={ref1}>
        		
        		<img id='port_image1' src={Newsapp} alt="Newsapp" />
				<div id='overlay1' className="overlay_1"> 
            		<div className="text">
					<h1>API driven News app</h1>
                        <br></br>
						<p>News App Template</p>
                        <p>with user subscriptions</p>
                        <p>and up to date news articles</p> 
                        <span></span>
                        <span></span>
                        <span></span>
                        <a id="sourcecode" className="port_nav-link" href="https://github.com/westaxis66/problem-sets-HarvardEdx-Web-development/tree/4e7c3d80c80625b8ed0b9b21026cf8f98c288146/capstone">Source code</a> 
				</div>	
				</div>
        		
      		</TwistingDiv>

			<TwistingDiv position="right" triggerPoint={50} forwardedRef={ref2}>
        		
        		<img id='port_image2' src={Auctions} alt="Auctions" />
				<div id='overlay2' className="overlay_1">
            		<div className="text">
					<h1>Auction Website</h1>
                        <br></br>
						<p>Auction Template</p>
                        <p>with Posting,</p>
                        <p>Bidding and folloing features </p> 
                        <span></span>
                        <span></span>
                        <span></span>
                        <a id="sourcecode" className="port_nav-link" href="https://github.com/westaxis66/problem-sets-HarvardEdx-Web-development/tree/4e7c3d80c80625b8ed0b9b21026cf8f98c288146/commerce">Source code</a>
				</div>	
				</div>
        		
      		</TwistingDiv>
			  <TwistingDiv  position="left" triggerPoint={50} forwardedRef={ref3}>
        		
        		<img id='port_image3' src={Social_Network} alt="Social_Network" />
				<div id='overlay3' className="overlay_1">
            		<div id='text_overlay3' className="text">
					<h1>Social Media app</h1>
                        <br></br>
						<p>Social Media Template</p>
                        <p>Log in and out features</p>
						<p>along with posting, </p>
                        <p id='p_overlay3'>following and liking features</p> 
						
                        <span></span>
                        <span></span>
                        <span></span>
                        <a id="sourcecode" className="port_nav-link" href="https://github.com/westaxis66/problem-sets-HarvardEdx-Web-development/tree/4e7c3d80c80625b8ed0b9b21026cf8f98c288146/network">Source code</a> 
				</div>	
				</div>
        		
      		</TwistingDiv>
			<TwistingDiv position="right" triggerPoint={50} forwardedRef={ref4}>
        		
        		<img id='port_image4' src={Search} alt="Search" />
				<div id='overlay4' className="overlay_1">
            		<div className="text">
					<h1>Search Engine</h1>
                        <br></br>
						<p>Search Template</p>
                        <p>Utilizing google search as a</p>
                        <p> proxiy for a customized</p>
                        <p>search bar on your website</p> 
                        <span></span>
                        <span></span>
                        <span></span>
                        <a id="sourcecode" className="port_nav-link" href="https://github.com/westaxis66/problem-sets-HarvardEdx-Web-development/tree/4e7c3d80c80625b8ed0b9b21026cf8f98c288146/search">Source code</a>
				</div>	
				</div>
        		
      		</TwistingDiv>
			<TwistingDiv  position="left" triggerPoint={50} forwardedRef={ref5}>
        		
        		<img id='port_image5' src={Mail} alt="Mail" />
				<div id='overlay5' className="overlay_1">
            		<div className="text">
					<h1>E-Mail App</h1>
                        <br></br>
						<p>E-mail Template</p>
                        <p>full servive email</p>
                        <p>compatible with any</p>
                         <p>email adress</p> 
                        <span></span>
                        <span></span>
                        <span></span>
                        <a id="sourcecode" className="port_nav-link" href="https://github.com/westaxis66/problem-sets-HarvardEdx-Web-development/tree/4e7c3d80c80625b8ed0b9b21026cf8f98c288146/mail">Source code</a>
				</div>	
				</div>
        		
      		</TwistingDiv>

			<TwistingDiv position="right" triggerPoint={50} forwardedRef={ref6}>
        		
        		<img id='port_image6' src={Encyclopedia} alt="Encyclopedia" />
				<div id='overlay6' className="overlay_1">
            		<div className="text">
					<h1>Encyclopedia Site</h1>
                        <br></br>
                        <p>Wiki Style Template</p>
                        <p>Encyclopedia site</p> 
						<p>allowing users to add to post</p> 
                        <span></span>
                        <span></span>
                        <span></span>
                        <a id="sourcecode" className="port_nav-link" href="https://github.com/westaxis66/problem-sets-HarvardEdx-Web-development/tree/4e7c3d80c80625b8ed0b9b21026cf8f98c288146/wiki">Source code</a>
				</div>	
				</div>
        		
      		</TwistingDiv>
			

			</div>
			
			<Footer/>
		</div>
	);
};

export default Portfolio;


/*useEffect(() => {
		// Scroll to the top when the component mounts
		window.scrollTo(0, 0);
	  }, []); // Empty dependency array ensures this effect runs only once*/
