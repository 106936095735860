import React, { useEffect, useRef, useState } from 'react';
import '../App.css';
import TengucuteImage from '../images/tengulogocute.png';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { motion } from 'framer-motion';


const useFooter = () => {
  const getCurrentYear = () => new Date().getFullYear();

  return {
    currentYear: getCurrentYear(),
  };
};

const Footer = () => {
  const { currentYear } = useFooter();
  const [isVisible, setIsVisible] = useState(false);
  const footerDivRef = useRef(null);
  const footerRef = useRef(null);
  const canvasRef = useRef(null);

  


  const handleScroll = () => {
    if (footerDivRef.current) {
      const elementTop = footerDivRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      // Adjust this value to control when the slide-in should start
      const triggerOffset = 100;

      if (elementTop < windowHeight - triggerOffset) {
        setIsVisible(true);
        console.log( "element triggered" )
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check visibility on initial render
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const footer = footerRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let particlesArray = [];
    const mouse = { x: 0, y: 0 };

    canvas.width = footer.offsetWidth;
    canvas.height = footer.offsetHeight;

    window.addEventListener('resize', () => {
      canvas.width = footer.offsetWidth;
      canvas.height = footer.offsetHeight;
    });

    const createParticle = (x, y) => {
      const particle = {
        x,
        y,
        size: Math.random() * 10 + 1,
        speedX: Math.random() * 2 - 1,
        speedY: Math.random() * 2 - 1,
        alpha: 1,
        life: Math.random() * 10 + 20,
      };
      particlesArray.push(particle);
    };

    const handleMouseMove = (e) => {
      const rect = canvas.getBoundingClientRect();
      mouse.x = e.clientX - rect.left;
      mouse.y = e.clientY - rect.top;
      for (let i = 0; i < 5; i++) {
        createParticle(mouse.x, mouse.y);
      }
    };

    const updateParticles = () => {
      for (let i = 0; i < particlesArray.length; i++) {
        const p = particlesArray[i];
        p.x += p.speedX;
        p.y += p.speedY;
        p.alpha -= 0.01;
        if (p.alpha <= 0) {
          particlesArray.splice(i, 1);
          i--;
        }
      }
    };

    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = 'source-over';
      for (const p of particlesArray) {
        ctx.fillStyle = `rgba(148, 0, 211, ${p.alpha})`;
        ctx.beginPath();
        ctx.arc(p.x, p.y, p.size, 0, Math.PI * 2);
        ctx.fill();
      }
    };

    const animate = () => {
      updateParticles();
      drawParticles();
      requestAnimationFrame(animate);
    };

    footer.addEventListener('mousemove', handleMouseMove);
    animate();

    return () => {
      footer.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);


  return (
    <footer id='footer1' ref={footerRef}>
      <canvas ref={canvasRef} id="waterCanvas"></canvas>
      
      <div id="footerdiv1" className={`slide-in-div-footer ${isVisible ? 'visible' : ''}`}
        ref={footerDivRef}>
                <div id="tengucute_div">
                  <img id='tengucute_image' src={TengucuteImage} alt="TengucuteImage"/>
                </div>
                <div id="yokaifooter_header">
                  <p>Yokai</p>
                </div>
                <div id='footerdiv2'>
                  <p id="softwarelogo">Software and Web Development</p>
                </div>
                <div id='footerdiv3'>
                  <p>Look for us on these social media platforms</p>
                </div>
                <div id='footerdiv4'>
                  <div id='yokaifooter_header2'> <h2>Contact Us</h2></div>
                  <div id='footer_contactinfo'>
                    <p>phone: 941-613-4645</p>
                    <p>email: yokaisoftware66@gmail.com</p>
                  </div>
                </div>
                <div id='footerdiv5'>
                      <a href="/contact">
                      <motion.div
                        id='href_footer'
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.8 }}
                        >Let's Get Started!
                      </motion.div></a>
                </div>
                <div id="social-icons">
                  <a id='fafacebook' href="https://www.facebook.com/profile.php?id=61559579340148&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                    <FaFacebook size={50} className="facebook" />
                  </a>
                  <a href="https://www.instagram.com/yokaisoftware?igsh=MTBjbWhiM294NXNxbA%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
                    <FaInstagram size={50} className="instagram" />
                  </a>
                  <a href="https://www.linkedin.com/company/yokai-software-and-web-development/about/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin size={50} className="linkedin" />
                  </a>
                </div>
            <div id='copyright'>
                <p>Copyright © {currentYear} Yokai Software</p>
            </div>
      </div>
    </footer>
  );
};

export default Footer;


//style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }}