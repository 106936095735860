import React, { useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha'; 
import '../App.css';

function ContactForm() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    company: '',
    serviceOfInterest: '',
    message: '',
    recaptchaToken: '', // Add a new state to store the reCAPTCHA token
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
  e.preventDefault();

  if (!formData.recaptchaToken) {
    alert('Please complete the reCAPTCHA challenge.');
    return;
  }

  try {
    const formDataEncoded = new FormData();
    formDataEncoded.append('entry.91827986', formData.firstName);
    formDataEncoded.append('entry.411875898', formData.lastName);
    formDataEncoded.append('entry.540761418', formData.email);
    formDataEncoded.append('entry.1062710132', formData.phoneNumber);
    formDataEncoded.append('entry.1350701758', formData.company);
    formDataEncoded.append('entry.711825896', formData.serviceOfInterest);
    formDataEncoded.append('entry.1702560729', formData.message);

    // Send form data to the Google Form
    const response = await axios.post(
      'https://docs.google.com/forms/d/e/1FAIpQLSehGe9ap9bh-f77RtEz50R9wMzsOqGuoz0AdCl5VOQcfc2NFg/formResponse',
      formDataEncoded
    );

    // Handle the response as needed
    console.log('Google Form Response:', response.data);

    // Reset the form fields
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      company: '',
      serviceOfInterest: '',
      message: '',
      recaptchaToken: '', // Clear the reCAPTCHA token after submission
    });
  
    alert('Form data submitted successfully!');
  } catch (error) {
    // Ignore error since the submission is successful
    console.log('Error submitting form:', error);
    // Reset the form fields even if there's an error
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      company: '',
      serviceOfInterest: '',
      message: '',
      recaptchaToken: '', // Clear the reCAPTCHA token after submission
    });
  }
};

// Add a function to handle reCAPTCHA token
const handleRecaptchaChange = (token) => {
  setFormData({
    ...formData,
    recaptchaToken: token // Update the reCAPTCHA token in the form data
  });
};


  return (
    <div id='formcontainer'>
      <div id='contact_form_title' style={{ paddingTop: '60px' }}><h2 style={{ fontSize: '35px' }}>Contact Us</h2></div>
      <form onSubmit={handleSubmit}>
        {/* Your form input fields */}
        <div id='firstnamediv' className="form-group">
        <label htmlFor="firstName" id='firstname'>First Name:</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
            style={{ width: '90%', padding: '10px', 
            border: '2px solid #ccc', borderRadius: '5px', fontSize: '14px' }}
          />
        </div>
        <div id='lastnamediv' className="form-group">
        <label htmlFor="lastName" id='lastname'>Last Name:</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
            style={{ width: '90%', padding: '10px', 
            border: '2px solid #ccc', borderRadius: '5px', fontSize: '14px' }}
          />
        </div>
        <div id='emaildiv' className="form-group">
          <label htmlFor="email" id='email2'>Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            style={{ width: '90%', padding: '10px', 
            border: '1px solid #ccc', borderRadius: '5px', fontSize: '14px' }}
          />
        </div>
        <div id='phonediv' className="form-group">
         <label htmlFor="phoneNumber" id='phone'>Phone Number:</label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            style={{ width: '90%', padding: '10px', 
            border: '1px solid #ccc', borderRadius: '5px', fontSize: '14px' }}
          />
        </div>
        <div id='companydiv' className="form-group">
          <label htmlFor="company" id='company2'>Company:</label>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleChange}
            required
            style={{ width: '94.5%', padding: '10px', marginLeft: '1%',
            border: '1px solid #ccc', borderRadius: '5px', fontSize: '14px' }}
          />
        </div>
        <div id='servdiv' className="form-group">
        <label htmlFor="serviceOfInterest" id='service'>Service of Interest:</label>
          <input
            type="text"
            id="serviceOfInterest"
            name="serviceOfInterest"
            value={formData.serviceOfInterest}
            onChange={handleChange}
            required
            style={{ width: '94.5%', padding: '10px', marginLeft: '1%', 
            border: '1px solid #ccc', borderRadius: '5px', fontSize: '14px' }}
          />
        </div>
        <div id='messagediv' className="form-group">
          <label htmlFor="message" id='message2'>Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            style={{ width: '94.5%', padding: '10px', marginLeft: '1%',
            border: '1px solid #ccc', borderRadius: '5px', fontSize: '14px' }}
          ></textarea>
        </div>
        <ReCAPTCHA id='recaptcha'
            sitekey="6LdwstIpAAAAAL1_lBneLQEQRHpyUpxdO5ZXJ0R9"
            onChange={handleRecaptchaChange}
          />


        <button id='formbutton' type="submit">Submit</button>
        
      </form>
    </div>
  );
}

export default ContactForm;
