// VideoComponent.js
import React, { useEffect, useState } from 'react';

const VideoComponent = ({ fadeTrigger, fadeDuration, videoSrc }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [videoOpacity, setVideoOpacity] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      setScrollPosition(currentPosition);

      if (currentPosition >= fadeTrigger) {
        const opacity = 1 - Math.min((currentPosition - fadeTrigger) / (fadeDuration * 100), 1);
        setVideoOpacity(opacity);
      } else {
        setVideoOpacity(1);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [fadeTrigger, fadeDuration]);

  return (
    <div style={{ opacity: videoOpacity }}>
      <video autoPlay="autoplay" playsInLine="playsinline" loop="true" muted="true" id="video-background">
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoComponent;
