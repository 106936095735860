import React, { useEffect, useRef, useState } from 'react';
import '../App.css';



const SlideInDiv = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const slideInDivRef = useRef(null);

  const handleScroll = () => {
    if (slideInDivRef.current) {
      const elementTop = slideInDivRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (elementTop < windowHeight) {
        setIsVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={`slide-in-div ${isVisible ? 'visible' : ''}`}
      ref={slideInDivRef}
    >
      {children}
    </div>
  );
};

export default SlideInDiv;
