import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

export const Nav = styled.nav`
background: transparent;
height: 85px;
display: flex;
justify-content: space-between;
padding: 0.2rem calc((100vw - 1000px) / 2);
z-index: 20;
position: fixed; 
top: 0;
font-size: 1.3rem;
width: 100%;

@media screen and (max-width: 1080px) {
  display: none;
  &.active {
    display: block;
    animation: fadeInDown 0.5s ease-in-out forwards;
  }
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-15px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
}

`;
// Define a keyframe animation for the dropdown
const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;



const underLine = keyframes`
  from {
    width: 0%;
    left: 50%;
  }
  to {
    width: 50%;
    left: 0%;
  }
`;

const underLine2 = keyframes`
  from {
    width: 0%;
    left: 50%;
  }
  to {
    width: 50%;
    left: 50%;
  }
`;


export const NavLink = styled(Link)`
position: relative;
display: flex;
align-items: center;
text-decoration: none;
margin-left: 25%;
height: 100%;
cursor: pointer;
transition: 0.3s ease;


@media screen and (max-width:1080px) {
  padding-top: 30%;
  height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;
  margin-left: 40%;
  
}

@media screen and (max-width:1024px) {
  margin-left: 38%;
}

@media screen and (max-width:926px) {
  padding-top: 15%;
}

@media screen and (max-width:915px) {
  padding-top: 15%;
}

@media screen and (max-width:854px) {
  padding-top: 12%;
}

@media screen and (max-width:844px) {
  padding-top: 14%;
}

@media screen and (max-width:812px) {
  margin-left: 35%;
  padding-top: 12%;
}

@media screen and (max-width:810px) {
  margin-left: 35%;
  padding-top: 15%;
}

@media screen and (max-width:800px) {
  padding-top: 10%;
}

@media screen and (max-width:768px) {
  margin-left: 30%;
  padding-top: 12%;
}

@media screen and (max-width:480px) {
  margin-left: 25%;
}

@media screen and (max-width:428px) {
  margin-left: 20%;
}

@media screen and (max-width:412px) {
  margin-left: 19%;
}

@media screen and (max-width:375px) {
  margin-left: 17%;
}

@media screen and (max-width:360px) {
  margin-left: 15%;
}

&.active {
	color: rgb(151, 5, 19);
  border-bottom: 4px solid;
  
}
&.services {
  &:hover,
  &.active {
    border-bottom: none;;
  }
}
&.logo {
  &:hover,
  &.active {
    border-bottom: none;
  }
}
&.home {
  &.active {
    border-bottom: none;
  }
}

&:hover {
  color: rgb(121, 12, 164);

    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 50%; /* Start from the center */
      width: 0; /* Start with no width */
      height: 4px;
      background-color: ${props => props.style && props.style.color};  /* Set default underline color */
      transition: width 0.3s ease, left 0.3s ease;
    }
  
    &:before {
      
      animation: ${underLine} 0.5s ease forwards;
    }
  
    &:after {
      
      animation: ${underLine2} 0.5s ease forwards;
    }
  
    &:hover::before {
      width: 35%; /* Extend to the left */
     
    }
  
    &:hover::after {
      width: 35%; /* Extend to the right */
      left: 50%;
    }
  
&:hover {
	color: rgb(121, 12, 164);
	.dropdown-content {
      display: block;
      animation: ${fadeInDown} 0.3s ease-in-out forwards; /* Apply the fade-in animation */
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: grey;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    animation: none; /* Avoid conflicts with hover animation */

    a {
      margin-left:-.5%;
      padding: 12px 16px;
      text-decoration: none;
      
      display: block;
    }
  }
`;

export const Bars = styled(FaBars)`
display: none;
color: #808080;
@media screen and (max-width: 768px) {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(-100%, 75%);
	font-size: 1.8rem;
	cursor: pointer;
}
`;

export const NavMenu = styled.div`
display: flex;
align-items: center;
/* Second Nav */
/* margin-right: 24px; */
/* Third Nav */
/* width: 100vw;
white-space: nowrap; */
@media screen and (max-width: 1080px) {
	display: none;
  width: 80%;
  padding-bottom: 10%;
  margin-left: 10%;
  padding-left: 27%;
  padding-right: 30%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  &.active {
    display: block;
    background-color: black;
    animation: fadeInDown 0.5s ease-in-out forwards;
  }
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-15px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
}

`;






/*&:hover {
        background-color: #ddd;
      }*/